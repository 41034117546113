import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function CommingSoon() {
    const navigate=useNavigate()
    const gotoPrev=()=>{
        navigate(-1)
    }
    return (
        <div className="flex  bg-[url(./assets/Welcome.png)] bg-cover min-w-full min-h-screen items-center justify-center overflow-hidden">

            <div className="flex flex-col w-full">
                <div className='flex flex-col items-center justify-center gap-8'>
                    <div className='flex flex-col items-center gap-2.5'>
                        <p className='items-center text-white text-[24px]  font-semibold leading-8'>Comming Soon</p>
                        <p className='text-[14px] font-normal text-[#637592] leading-5'>This part will be open in next version</p>
                    </div>
                    <button className='flex flex-row bg-[#1665FF] px-6 py-3 gap-2 rounded-xl' onClick={gotoPrev}>
                        <div className='flex w-[24px] h-[24px] items-center'>
                        <img src='../assets/back.png' alt='bakc'/>
                        </div>
                        <p className='text-[16px] text-white leading-6 font-semibold'>Back to previous page</p>
                    </button>
                </div>
            </div>

        </div>
    )
}
