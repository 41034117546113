import React from 'react'
import { useState } from 'react'
import { CgSupport } from 'react-icons/cg'
import { CiSettings, CiUser } from 'react-icons/ci'
import { FaRegCircleUser } from 'react-icons/fa6'
import { FiFilePlus } from 'react-icons/fi'
import { HiOutlineChartBar, HiOutlinePaperAirplane, HiOutlineViewGridAdd } from 'react-icons/hi'
import { IoDocumentTextOutline } from 'react-icons/io5'
import { MdOutlineNotificationsActive } from 'react-icons/md'
import { PiLightningLight } from 'react-icons/pi'
import { TbLogout } from 'react-icons/tb'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { Link, Outlet, useNavigate } from "react-router-dom"



const Mydrawer = () => {

    const menuOptions1 = [
        {
            icon: <FiFilePlus color="white" />,
            label: 'Draft Transaction',
            link: '/draft-transaction',
        },
        {
            icon: <FiFilePlus color="white" />,
            label: 'Ordinal Sniper',
            link: '/ordinal-sniper',
        },
        {
            icon: <IoDocumentTextOutline color="white" />,
            label: 'In Progress',
            link: '/in-progress',
        },
        {
            icon: <HiOutlineChartBar color="white" />,
            label: 'Mempool',
            link: '/mempool',
        },
        {
            icon: <PiLightningLight color="white" />,
            label: 'Speed Up(RBF)',
            link: '/rbf',
        },
        {
            icon: <HiOutlinePaperAirplane color="white" />,
            label: 'Send',
            link: '/send',
        },
        {
            icon: <HiOutlineViewGridAdd color="white" />,
            label: 'Portfolio',
            link: '/portfolio',
        },
        {
            icon: <CiUser color="white" />,
            label: 'Profile',
            link: '/profile',
        },
        {
            icon: <FaRegCircleUser color="white" />,
            label: 'Leaderboard',
            link: '/users',
        },
        {
            icon: <MdOutlineNotificationsActive color="white" />,
            label: 'Notifications',
            link: '/notificatoins',
        },
    ];

    const menuOptions2 = [
        {
            icon: <CiSettings color="white" />,
            label: 'Settings',
            link: '/settings',
            color: 'text-white',
            onClick: () => handleSettingsClick(),
        },
        {
            icon: <CgSupport color="white" />,
            label: 'Help',
            link: '/help',
            color: 'text-white',
            onClick: () => handleHelpClick(),
        },
        {
            icon: <TbLogout color="#FF7D7D" />,
            label: 'Logout',
            link: '/logout',
            color: 'text-[#FF7D7D]',
            onClick: () => handleLogoutClick(),
        },
    ];
    const handleClick = () => { }

    const handleHelpClick = () => { }

    const handleSettingsClick = () => { }


    const navigate = useNavigate();



    const [isOpen, setIsOpen] = React.useState(false)
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }
    const [menuIndex, setMenuIndex] = useState(-1);

    const handleLogoutClick = () => {
        navigate("/")
        localStorage.clear();
    }


    return (
        <>
            <button onClick={toggleDrawer}><img src='../assets/menu.png' alt='toggle'/></button>
            <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction='left'
                className=''
            >
                <div>
                    <div className="flex flex-row ">

                        <div className="flex flex-col bg-[url(./assets/Welcome.png)]   min-w-[236px] min-h-screen pr-[16px]  pl-[16px]"
                            onClick={handleClick}>
                            {/* logo */}
                            <div className="flex flex-row items-center mx-auto ">
                                <img src="../assets/Frame.png" alt="logo" />
                            </div>
                            <div className="flex flex-row items-start mx-auto ">
                                <img src="../assets/Frame1.png" alt="logo1" />
                            </div>
                            {/* mainsidebar */}
                            <div className="flex flex-col  max-h-[532px] gap-1">
                                {menuOptions1.map((option, index) =>
                                    menuIndex !== index ?
                                        (<Link key={"L"+index} to={option.link} onClick={() => setMenuIndex(index)} >
                                            <div key={index} className="flex flex-row justify-start  pr-[16px] pb-[16px] pt-[12px] pl-[12px]  hover:bg-[#4665FF] hover:rounded-xl focus:bg-[#1665FF] ">
                                                <button key={"l" + index} className="flex items-center flex-row gap-2 ">
                                                    <div key={"o" + index}>{option.icon}</div>
                                                    <p key={"k" + index} className="text-white text-[16px] leading-6 font-semibold font-manrope ">
                                                        {option.label}
                                                    </p>
                                                </button>
                                            </div>
                                        </Link>
                                        ) :
                                        (<Link key={"L"+index} to={option.link} onClick={() => setMenuIndex(index)} >
                                            <div key={index} className="flex flex-row justify-start pr-[16px] pb-[16px] pt-[12px] pl-[12px] rounded-xl  hover:bg-[#1665FF] focus:bg-[#1665FF] bg-[#1665FF]">
                                                <button key={"l" + index} className="flex items-center flex-row gap-2 ">
                                                    <div key={"o" + index}>{option.icon}</div>
                                                    <p key={"k" + index} className="text-white text-[16px] leading-6 font-semibold font-manrope">
                                                        {option.label}
                                                    </p>
                                                </button>
                                            </div>
                                        </Link>)
                                )}
                            </div>

                            {/* others */}
                            <div className="flex flex-col max-w-[204px] max-h-[152px]  mt-auto mb-3">
                                {menuOptions2.map((option, index) => (
                                    menuIndex !== index ? (
                                        <div key={index} className="flex flex-row justify-start  pr-[16px] pt-[12px] pb-[12px] pl-[16px]  hover:bg-[#4665FF] hover:rounded-xl focus:bg-[#1665FF] "
                                            onClick={option.onClick}
                                        >
                                            <button key={"b" + index}
                                                className="flex items-center flex-row gap-2"
                                            >
                                                <div key={"d" + index}>{option.icon}</div>
                                                <p key={"p" + index} className={`${option.color} text-[16px] leading-6 font-semibold font-manrope `}>
                                                    {option.label}
                                                </p>
                                            </button>
                                        </div>) : (
                                        <div key={index} className="flex flex-row justify-start  pr-[16px] pt-[12px] pb-[12px] pl-[16px]  rounded-xl bg-[#1665FF] "
                                            onClick={option.onClick}
                                        >
                                            <button key={"b" + index}
                                                className="flex items-center flex-row gap-2"
                                            >
                                                <div key={"d" + index}>{option.icon}</div>
                                                <p key={"p" + index} className={`${option.color} text-[16px] leading-6 font-semibold font-manrope `}>
                                                    {option.label}
                                                </p>
                                            </button>
                                        </div>
                                    )

                                ))}
                            </div>
                        </div>

                        <Outlet />
                    </div>
                </div>
            </Drawer>
        </>
    )
}

export default Mydrawer


