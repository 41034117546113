export const WalletType = {
    Xverse: 'Xverse',
    Unisat: 'Unisat',
    Leather: 'Leather',
    Phatom: 'Phantom',
    MagicEden: 'MagicEden',
    OKX: 'Okx'
}

export const BACKEND_URL_PURE = 'https://membual-be-final.vercel.app';
export const BACKEND_URL = `${BACKEND_URL_PURE}/api`;
export const NEXT_PUBLIC_ORDINAL_URL=`https://testnet-explorer.ordinalsbot.com/content`

export const TEST_MODE = true;