import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Pagination, TableFooter } from '@mui/material';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { BACKEND_URL } from '../configs/config';
import { IProfile, ITransaction } from '../type';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Loading } from './loading';
import { AppContext } from '../context/UserContext';
import { useContext } from 'react';
import { NoTransaction } from './notransaction';

interface FetchTransactionProps {
    flag: string;
}



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        paddingRight: 0,
        paddingLeft: 0,
        paddingTop: 4,
        paddingBottom: 4,

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        paddingRight: 0,
        paddingLeft: 0,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    gap: 4,
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));



export const Txtable: React.FC<FetchTransactionProps> = (flag) => {
    const navigate = useNavigate();
    const appContext = useContext(AppContext)

    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [showRBF, setShowRBF] = useState<boolean>(false);
    const [transactions, setTransactions] = useState<ITransaction[]>([]);
    const [rows, setRows] = useState<ITransaction[]>([]);
    const [row, setRow] = useState<ITransaction>();
    const [currentPage, setCurrentPage] = useState(1);
    const [profile, setProfile] = useState<IProfile>()
    const [isLoading, setIsLoading] = useState(true);
    const newFeeRef = React.useRef(null);

    const walletType = appContext?.walletType;

  

    const handlePageChange = (event: any, value: any) => {
        //  setRowsPerPage(parseInt((event).target.value, 10));
        setPage(value);
    };

    const getProcessTx = async () => {
        try {
            const response = await axios.get<{ success: boolean; message: string; payload: ITransaction[] }>(`${BACKEND_URL}/tx/getProcessTx`, {
                params: {
                    profileId: appContext?.profileId
                },
            });
            console.log("getProcessTx response ==> ", response.data.payload);

            if (response.data.success) {
                setTransactions(response.data.payload);
            } else {
                console.log(response.data.message);
            }

        } catch (err) {
            console.error(err);

        }
    };

    const init = async () => {
        await getProcessTx();
        await getProfileByPaymentAddress();
        setIsLoading(false);
    }

    useEffect(() => {
        init();
    }, [transactions,,profile]);

    useEffect(() => {
        if (transactions) {
            const tempArr: ITransaction[] = [];
            // transactions.map((tx: any, index: number) => {
            for (const tx of transactions) {
                tempArr.push({
                    _id: tx._id,
                    profileId: tx.profileId,
                    isDraft: tx.isDraft,
                    status: tx.status,
                    feeRate: tx.feeRate,
                    amountToTransfer: tx.amountToTransfer,
                    destinationAddress: tx.destinationAddress,
                    inscriptionId: tx.inscriptionId,
                    paymentAddress: tx.paymentAddress,
                    txId: tx.txId,
                    type: tx.type
                })
            }
            console.log("tempArr ==> ", tempArr);
            setRows(tempArr);
        }
    }, [transactions])

    const handleRBF = (selectedRow: any) => {
        console.log("Accelerating selectedRow:", selectedRow);
        setShowRBF(true);
        // setSelectedTransactionID(transactionID);
        setRow(selectedRow);
    }
    const closeHandle = () => {
        setShowRBF(false)
    }
    const getProfileByPaymentAddress = async () => {
        try {
            const response = await axios.get(`${BACKEND_URL}/profile/getByPaymentAddress?paymentAddress=${appContext?.paymentAddress}`);
            setProfile(response.data.payload)
            console.log(profile);
        } catch (error) {
            throw error;
        }
    };

    

    const rbfHandler = async (payload: any) => {
        const url = `${BACKEND_URL}/tx/rbf`
        const rbfResult = await axios.post(url, payload);
        console.log("rbfResult ==> ", rbfResult.data);

        return rbfResult.data;
    }

    const rbfSubmitFunc = async () => {
        console.log("rbfSubmitFunc row ==> ", row);
        const newFeeRate = (newFeeRef.current as any).value;
        let payload = {}
        if (newFeeRate) {
            payload = {
                txId: row?.txId,
                type: walletType,
                newFeeRate,
            }
        }

        console.log("payload in rbfSubmitFunc ==> ", payload);
        const psbt = await rbfHandler(payload);

        console.log('psbt.psbtHex in RBF ==> ', psbt.psbtHex);
        const signedPSBT = await (window as any).unisat.signPsbt(psbt.psbtHex);
        console.log("signedPSBT ==> ", signedPSBT);

        console.log("payload ==> ", {
            psbt: psbt.psbtHex,
            signedPSBT,
            walletType,
            txId: row?.txId,
            feeRate: newFeeRate,
        })

        const txIdPayload = await axios.post(`${BACKEND_URL}/tx/rbf-exec`, {
            psbt: psbt.psbtHex,
            signedPSBT,
            walletType,
            txId: row?._id,
            feeRate: newFeeRate,
        })
        const txId = txIdPayload.data.msg;

        console.log("txID ==> ", txId);
    }

    if (isLoading) {
        return (
            <div className="flex min-h-screen">
                <div className="flex flex-col w-full">
                    <Loading />
                </div>

            </div>
        )
    }

    return (
        <div >
            {/* <button onClick={() => navigate('/detail')}><p className='text-white'>Test button</p></button> */}
            {
                transactions.length ?
                    <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow >
                                    <StyledTableCell align="center" style={{ backgroundColor: "#252B35", border: 'none', display: 'flex', justifyContent: 'center', paddingLeft: '8px' }} >No</StyledTableCell>
                                    <StyledTableCell align="center" style={{ backgroundColor: "#252B35", border: 'none', }} className='truncate'>TransactionID</StyledTableCell>
                                    <StyledTableCell align="center" style={{ backgroundColor: "#252B35", border: 'none', }} className='truncate'> Wallet Address</StyledTableCell>
                                    <StyledTableCell align="center" style={{ backgroundColor: "#252B35", border: 'none', display: 'flex', justifyContent: 'center', }}>FeeRate</StyledTableCell>
                                    <StyledTableCell align="center" style={{ backgroundColor: "#252B35", border: 'none', }}>inscriptionId</StyledTableCell>
                                    <StyledTableCell align="center" style={{ backgroundColor: "#252B35", border: 'none', display: 'flex', justifyContent: 'center' }} >Action</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={{ display: 'felx', flexDirection: 'row' }}>

                                {rows ? rows.slice((page - 1) * rowsPerPage, page * rowsPerPage).map(
                                    (row, index) =>
                                        <StyledTableRow key={index + 1} style={{
                                            backgroundColor: index % 2 === 0 ? '#191D24' : '#18191D',
                                        }}>
                                            <StyledTableCell
                                                align="center"
                                                component="th"
                                                scope="row"
                                                style={{ color: 'white', border: 'none', fontSize: "12px", padding: '8px 8px 8px 10px' }}
                                                className='w-[16px] h-[16px]'
                                            >
                                                {index + 1}
                                            </StyledTableCell>
                                            <StyledTableCell
                                                align="center"
                                                style={{ color: '#1665FF', border: 'none', fontSize: "12px", justifyContent: 'center', paddingLeft: '8px 0' }}
                                                className='truncate max-w-[60px]'
                                            >
                                                {row.txId}
                                            </StyledTableCell>
                                            <StyledTableCell
                                                align="center"
                                                style={{ color: 'white', border: 'none', fontSize: "12px", padding: '8px 0', }}
                                                className='truncate max-w-[10px]'
                                            >
                                                {row.paymentAddress}
                                            </StyledTableCell>
                                            <StyledTableCell
                                                align="center"
                                                style={{ color: 'white', border: 'none', fontSize: "12px", padding: '8px 0', display: 'flex', justifyContent: 'center', }}>
                                                {row.feeRate}
                                            </StyledTableCell>
                                            <StyledTableCell
                                                align="center"
                                                style={{ color: 'white', border: 'none', fontSize: "12px", padding: '8px 0', }}
                                                className='truncate max-w-[100px] text-center'
                                            >
                                                {row.inscriptionId}
                                            </StyledTableCell>
                                            <StyledTableCell
                                                align="right"
                                                style={{
                                                    color: 'white', border: 'none', fontSize: "12px",
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    display: 'flex'
                                                }}
                                            >
                                                <div className='flex justify-center gap-2 '>
                                                    <button className="border-2 border-[#252B35] p-1 rounded-lg w-[24px] h-[24px]" onClick={() => navigate(`/detail/${row.txId}`)} >
                                                        <img src="../assets/eye.png" className='w-[12.72px] h-[9.33px]' alt='eue'/>
                                                    </button>
                                                    <button className='bg-[#006B7A] rounded-lg py-1 px-2'>
                                                        <p className='leading-2.5 font-semibold' onClick={() => handleRBF(row)}>
                                                            Accelerate
                                                        </p>
                                                    </button>
                                                </div>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                ) : (<StyledTableRow className='text-white'><StyledTableCell>No Transaction</StyledTableCell></StyledTableRow>)
                                }
                            </TableBody>

                        </Table>
                        <div className='flex flex-row justify-between bg-[#191D24]'>
                            <div></div>
                            <Pagination count={Math.ceil(rows.length / rowsPerPage)} page={page} onChange={handlePageChange}
                                style={{ backgroundColor: "#191D24" }}
                                variant="outlined"
                                shape="rounded"
                                color="primary"
                            />
                        </div>
                    </TableContainer> : <NoTransaction />
            }
            {showRBF ? (
                <div className="fixed inset-0 flex flex-col top-20">
                    <div className="flex flex-col gap-2 bg-[#191D24] mx-auto border-2  max-[765px]:w-[343px] min-[765px]:w-[500px] border-solid border-[#252B35] rounded-xl p-6">
                        <div className="flex flex-row justify-between px-4 py-5">
                            <div></div>
                            <h3 className="text-[24px]  font-bold font-manrope text-white leading-8">RBF Speed Up</h3>
                            <button onClick={() => closeHandle()}>
                                <img src="../assets/close.png" alt='close' />
                            </button>
                        </div>
                        <form encType='multipart/form-data'>
                            <div className="flex flex-col gap-4">
                                <div className="flex flex-col gap-1">
                                    <label className="font-manrope text-[14px] font-normal leading-6 text-[#637592]" >TransactionID</label>
                                    <p className="break-words text-white font-manrope text-[14px] leading-6 font-bold ">{row?.txId}</p>
                                </div>

                                <div className="flex flex-col gap-1">
                                    <label className="font-manrope text-[14px] font-normal leading-6 text-[#637592]">Destination Address</label>
                                    <p className="text-white font-manrope text-[14px] leading-6 font-semibold break-words">{row?.destinationAddress}</p>
                                </div>

                                {
                                    row?.type === "Ordinals" ?
                                        <div className="flex flex-col gap-1">
                                            <label className="font-manrope text-[14px] font-normal leading-6 text-[#637592]">Inscription Id</label>
                                            <p className="text-white font-manrope text-[14px] leading-6 font-semibold break-words">{row?.inscriptionId}</p>
                                        </div>
                                        :
                                        <div className="flex flex-col gap-1">
                                            <label className="font-manrope text-[14px] font-normal leading-6 text-[#637592]">Transfer Amount</label>
                                            <p className="text-white font-manrope text-[14px] leading-6 font-semibold break-words">{row?.amountToTransfer}</p>
                                        </div>
                                }

                                <div className="flex flex-col gap-1">
                                    <label className="font-manrope text-[14px] font-normal leading-6 text-[#637592]">Previous Fee Rate</label>
                                    <p className="text-white font-manrope text-[14px] leading-6 font-semibold break-words">{row?.feeRate}</p>
                                </div>

                                <div className="flex flex-col gap-1 ">
                                    <label className="font-manrope text-[14px] leading-6 font-normal text-[#637592]">Fee Rate</label>
                                    <input
                                        className="bg-[#16171B] rounded-xl px-4 py-3 gap-2 text-[#637592] focus:outline-none"
                                        placeholder="Input fee rate"
                                        ref={newFeeRef}
                                    ></input>
                                </div>

                                <div className="flex flex-row justify-between gap-1 pt-2">
                                    <label className="font-manrope text-[14px] leading-6 text-[#637592]">Estimated Time</label>
                                    <p className="break-words text-white font-manrope text-[14px] leading-6 font-semibold">10 mins</p>
                                </div>

                            </div>


                        </form>
                        <button
                            className="bg-[#1665FF] rounded-xl px-6 py-3 w-full hover:bg-blue-700"
                            onClick={() => rbfSubmitFunc()}
                        >
                            <p className="text-white font-manrope text-[14px] font-semibold leading-6 ">RBF Submit</p>
                        </button>
                    </div>
                </div>) : <></>}
        </div>
    );
}

