import { useState, useContext, useEffect, useCallback } from "react";
import LogoImage from "../assets/logo.png";
import "react-responsive-modal/styles.css";
import { AppContext } from "../context/UserContext";
import { getAddress, AddressPurpose, BitcoinNetworkType } from "sats-connect";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getProvider } from "../walletConnect/phantom";
import { WalletType } from "../configs/config";

import type { Wallet, WalletWithFeatures } from '@wallet-standard/base';
import { useWallet, useWallets } from '@wallet-standard/react';
import { ConnectionStatusContext } from '../context/ConnectionStatus';
import type { Account } from '../types';
import { useNavigate } from "react-router-dom";
const SatsConnectNamespace = 'sats-connect:';

function isSatsConnectCompatibleWallet(wallet: Wallet) {
  return SatsConnectNamespace in wallet.features;
}

export default function Membular() {

  const appContext = useContext(AppContext)


  const [open, setOpen] = useState(false);
  const onCloseModal = () => setOpen(false);
  const [walletOpen, setWalletOpen] = useState(false);
  const { wallets } = useWallets();
  const handleOpen = () => setWalletOpen((cur) => !cur);
  const navigate = useNavigate()


  const { setWallet, wallet } = useWallet();
  const connectionStatus = useContext(ConnectionStatusContext);
  const [showModal, setShowModal] = useState(false);
  const [navState, setNavState] = useState(false);

  useEffect(() => {

    if (navState) {
      navigate('/users');
    }
  }, [navState]);

  const connectXverseWallet = async () => {
    try {
      const getAddressOptions = {
        payload: {
          purposes: [AddressPurpose.Ordinals, AddressPurpose.Payment],
          message: "Address for receiving Ordinals",
          network: {
            type: BitcoinNetworkType.Testnet,
          },
        },
        onFinish: async (response: {
          addresses: {
            address: string;
            publicKey: string;
          }[];
        }) => {
          appContext?.updateOrdinalsAddress(response.addresses[0].address);
          appContext?.updateOrdinalsPublickey(response.addresses[0].publicKey);
          appContext?.updatePaymentAddress(response.addresses[1].address);
          appContext?.updatePaymentPublickey(response.addresses[1].publicKey);
          appContext?.updateWalletType(WalletType.Xverse);
          handleOpen();
          onCloseModal();
          setShowModal(false);
          setNavState(true)
        },
        onCancel: () => {
          setShowModal(true);
          toast.warn("Connecting canceled");
        },
        onError: (err: string) => {
          toast.warn(err);
        },
      };

      await getAddress(getAddressOptions).catch((err) => {
        toast.error(err.message);
      });

    } catch (error) {
      toast.warn("Please insatll the Xverse wallet!!");
    }
  };

  const connectUnisatWallet = async () => {
    try {
      const accounts = await (window as any).unisat.requestAccounts();
      const pubkey = await (window as any).unisat.getPublicKey();

      appContext?.updatePaymentAddress(accounts[0]);
      appContext?.updateOrdinalsAddress(accounts[0]);
      appContext?.updatePaymentPublickey(pubkey);
      appContext?.updateOrdinalsPublickey(pubkey);
      appContext?.updateWalletType(WalletType.Unisat);
      onCloseModal();
      setWalletOpen(true)
      setOpen(true)
      setNavState(true)
    } catch (e) {
      toast.warn("Please install the unisat wallet in your browser");
    }
  };

  function accountFromDerivationPath(path: string) {
    console.log(path);
    const segments = path.split("/");
    const account = parseInt(segments[3].replaceAll("'", ""), 10);
    if (isNaN(account)) throw new Error("Cannot parse account number from path");
    return account;
  }

  const connectLeatherWallet = async () => {

    const response = await (window as any).LeatherProvider?.request("getAddresses");

    accountFromDerivationPath(response.result.addresses[0].derivationPath)

    appContext?.updatePaymentAddress(response.result.addresses[0].address);
    appContext?.updatePaymentPublickey(response.result.addresses[0].publicKey);
    appContext?.updateOrdinalsAddress(response.result.addresses[1].address)
    appContext?.updateOrdinalsPublickey(response.result.addresses[1].publicKey)
    onCloseModal();
    setWalletOpen(true)
    setOpen(true)
    setNavState(true)
  };

  const connectPhantomWallet = async () => {
    try {
      const phantomProvider = getProvider();
      const accounts = await phantomProvider.requestAccounts();

      appContext?.updatePaymentAddress(accounts[0].address);
      appContext?.updatePaymentPublickey(accounts[0].publicKey);
      appContext?.updateOrdinalsAddress(accounts[1].address);
      appContext?.updateOrdinalsPublickey(accounts[1].publicKey);
      onCloseModal();
      setNavState(true)
    } catch (error) {
      toast.warn("Please insatll the Xverse wallet!!");
    }
  };

  const connectOkmWallet = async () => {
    try {
      const response = await (window as any).okxwallet.bitcoin.connect();
      const accounts = await (window as any).okxwallet.bitcoin.requestAccounts();
      const pubkey = await (window as any).okxwallet.bitcoin.getPublicKey();

      appContext?.updatePaymentAddress(accounts[0]);
      appContext?.updateOrdinalsAddress(accounts[0]);
      appContext?.updatePaymentPublickey(pubkey);
      appContext?.updateOrdinalsPublickey(pubkey);
      appContext?.updateWalletType(WalletType.OKX);
      onCloseModal();
      setNavState(true)
    } catch (err) {
      toast.error("You need check if the wallet is correctly installed")
    }
  };
  useEffect(() => {
    wallets.filter(isSatsConnectCompatibleWallet).map((wallet, index) => {

        setWallet(wallet)
    });
}, [wallets])

  const connectMEWallet = async () => {
    console.log("Connecting wallet...");
   
    try {
     
      const provider = (wallet as unknown as WalletWithFeatures<any>).features[SatsConnectNamespace]?.provider;
      if (!provider) {
        throw new Error("Provider not found in wallet features.");
      }
  
      const response = await getAddress({
        getProvider: async () => provider,
        payload: {
          purposes: [AddressPurpose.Ordinals, AddressPurpose.Payment],
          message: 'Address for receiving Ordinals and payments',
          network: {
            type: BitcoinNetworkType.Mainnet,
          },
        },
        onFinish: (response) => {
          connectionStatus?.setAccounts(response.addresses as unknown as Account[]);
          appContext?.updateOrdinalsAddress(response.addresses[0].address);
          appContext?.updateWalletType(WalletType.MagicEden);
          appContext?.updateOrdinalsPublickey(response.addresses[0].publicKey);
          appContext?.updatePaymentAddress(response.addresses[1].address);
          appContext?.updatePaymentPublickey(response.addresses[1].publicKey);
          handleOpen();
          onCloseModal();
          setNavState(true);
        },
        onCancel: () => {
          toast.error("You cancelled");
        },
      });
  
      console.log("Wallet connected successfully: ", response);
    } catch (error:any) {
      toast.error("Error connecting wallet. Please check if the wallet is correctly installed.");
      setWallet(null);
    }
  };


  return (
    <div className="flex bg-[url(./assets/Welcome.png)]  bg-cover items-center justify-center  min-w-full min-h-screen">
      <div className="flex flex-col items-center justify-center text-white">
        <div>
          <img src={LogoImage} alt="Logo" width="164.51px" height="80px" className="brightness-150" />
        </div>
        <div className="flex justify-center align-center">
          <p className="font-manrope text-[14px] text-[#637592] leading-5 font-normal">Connect your wallet to fasten </p>
        </div>
        <div className="flex justify-center align-center">
          <p className="font-manrope text-[14px] text-[#637592] leading-5 font-normal">your transactions</p>
        </div>
        <div className=" pt-[24px] flex  align-center justify-center">
          <button className="flex flex-row bg-[#1665FF] px-6 py-3 rounded-xl gap-2 items-center" onClick={() => setShowModal(true)}>
            <img src="../assets/Vector.png" className="w-5 h-4.5" alt="" />
            <label className="text-white text-[16px] leading-6 font-semibold">Connect Wallet</label>
          </button>
          {showModal ? (
            <>
              <div className="fixed inset-0 flex items-center">
                <div className="mx-auto ">
                  <div className="flex flex-col max-sm:w-[335px] max-sm:h-[486px] max-sm:justify-center ">
                    <div className="bg-[#191D24] rounded-2xl p-6 gap-6 ">
                      <div className="flex flex-row justify-between">
                        <div></div>
                        <button><p className="text-white text-[24px] leading-8 font-semibold">Connect Wallet </p></button>
                        <button className="" onClick={() => setShowModal(false)}><img className="w-6 h-6" src="../assets/close.png" alt="" /></button>
                      </div>
                      <div className="flex flex-col items-center w-full gap-2 p-8 text-white rounded shadow max-sm:p-6 ">
                        <div className="max-sm:grid max-sm:grid-cols-2 max-sm:grid-rows-2 grid grid-cols-3 grid-rows-2 gap-2 max-sm:w-[287px] max-sm:h-[412px] max-sm:items-center">
                          <button
                            className="flex bg-[#131417] broder-[#252B35] broder-1 flex-col items-center w-full px-4 py-8 rounded-xl gap-2"
                            onClick={() => connectXverseWallet()}
                          >
                            <img src="/assets/xverse.jpg" className="w-10 h-10 rounded-md" alt="" />
                            <p className="font-bold text-[16px] leading-5">Xverse</p>
                          </button>
                          <button
                            className="flex bg-[#131417] broder-[#252B35] broder-1 flex-col items-center w-full px-4 py-8 rounded-xl gap-2"
                            onClick={() => connectUnisatWallet()}
                          >
                            <img src="../assets/unisat.jpg" className="w-10 h-10 rounded-md " alt="" />
                            <p className="font-bold text-[16px] leading-5">Unisat</p>
                          </button>
                          <button
                            className="flex bg-[#131417] broder-[#252B35] broder-1 flex-col items-center w-full px-4 py-8 rounded-xl gap-2"
                            onClick={() => connectLeatherWallet()}
                          >
                            <img src="../assets/leather.png" className="w-10 h-10 rounded-md" alt="" />
                            <p className="font-bold text-[16px] leading-5">Leather</p>
                          </button>
                          <button
                            className="flex bg-[#131417] broder-[#252B35] broder-1 flex-col items-center w-full px-4 py-8 rounded-xl gap-2"
                            onClick={() => connectMEWallet()}
                          >
                            <img src="../assets/magic.jpg" className="w-10 h-10 rounded-md" alt="" />
                            <p className="font-bold text-[16px] leading-5">Magic Eden</p>
                          </button>
                          <button
                            className="flex bg-[#131417] broder-[#252B35] broder-1 flex-col items-center w-full px-4 py-8 rounded-xl gap-2"
                            onClick={() => connectPhantomWallet()}
                          >
                            <img src="../assets/phantom.png" className="w-10 h-10 rounded-md" alt="" />
                            <p className="font-bold text-[16px] leading-5">phantom</p>
                          </button>
                          <button
                            className="flex bg-[#131417] broder-[#252B35] broder-1 flex-col items-center w-full px-4 py-8 rounded-xl gap-2"
                            onClick={() => connectOkmWallet()}
                          >
                            <img src="../assets/okx.png" className="w-10 h-10 rounded-md" alt="oxk" />
                            <p className="font-bold text-[16px] leading-5">OKX</p>
                          </button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}