import { AppContext } from "../context/UserContext";
import { BACKEND_URL, BACKEND_URL_PURE, WalletType } from "../configs/config"
import { useEffect, useState, useRef, useContext } from "react";
import axios from "axios";
import { IAddress, IProfile, IWallet } from "../type";
import { useWallet, useWallets } from '@wallet-standard/react';
import { ConnectionStatusContext } from '../context/ConnectionStatus';
import { AddressPurpose, BitcoinNetworkType, getAddress } from "sats-connect";
import { getProvider } from "../walletConnect/phantom";
import type { Wallet, WalletWithFeatures } from '@wallet-standard/base';
import { IoCopyOutline } from "react-icons/io5";
import { Account } from "../types";
import { Loading } from "../component/loading"
import { IoCloseSharp } from "react-icons/io5";
import { BiPlus } from "react-icons/bi";
import Mydrawer from "../component/collapse";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { IoIosSave } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import { IoMdWarning } from "react-icons/io";
import { MdDeleteForever } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Header from "../component/Header";

const SatsConnectNamespace = 'sats-connect:';

function isSatsConnectCompatibleWallet(wallet: Wallet) {
  return SatsConnectNamespace in wallet.features;
}


export const Profile = () => {

  const appContext = useContext(AppContext)
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [profile, setProfile] = useState<IProfile>();
  const [walletOpen, setWalletOpen] = useState(false);
  const handleOpen = () => setWalletOpen((cur) => !cur);
  const { wallets } = useWallets();
  const [walletName, setWalletName] = useState('');
  const { setWallet, wallet } = useWallet();
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [newWalletName, setNewWalletName] = useState('');
  const [open, setOpen] = useState(false);//delete confirm modal
  const connectionStatus = useContext(ConnectionStatusContext);
  const [avatarURL, setAvatarURL] = useState("../assets/upload-photo-here.png");
  const fileUploadRef = useRef<HTMLInputElement | null>(null);
  const [selWallet, setSelWallet] = useState<IWallet>();



  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = (address:IWallet) => {
    setSelWallet(address);
    setOpen(true);
  };


  useEffect(() => {
    fetchSpecificProfiles();
  }, [profile]);


  const getProfileByPaymentAddress = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/profile/getByPaymentAddress?paymentAddress=${appContext?.paymentAddress}`);
      if (response.data.success) {
        // console.log(response.data.payload);
        return response.data.payload
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      throw error;
    }
  };

  const fetchProfileByProfileId = async () => {
    const profile = await getProfileByProfileId();
    setProfile(profile);
    console.log("profile ==> ", profile);
    setIsLoading(false)
  }

  const getProfileByProfileId = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/profile/getByProfileId?profileId=${profile?._id}`);
      if (response.data.success) {
        // console.log(response.data.payload);
        return response.data.payload
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      throw error;
    }
  };

  const fetchSpecificProfiles = async () => {
    try {
      const data = await getProfileByPaymentAddress();
      setProfile(data);
      setIsLoading(false)
    } catch (error) {
      // Display an error message to the user
    }
  };


  const connectXverseWallet = async (e: any) => {
    e.preventDefault()
    try {
      const getAddressOptions = {
        payload: {
          purposes: [AddressPurpose.Ordinals, AddressPurpose.Payment],
          message: "Address for receiving Ordinals",
          network: {
            type: BitcoinNetworkType.Mainnet,
          },
        },
        onFinish: async (response: {
          addresses: {
            address: string;
            publicKey: string;
          }[];
        }) => {

          appContext?.updateOrdinalsAddress(response.addresses[0].address);
          appContext?.updateOrdinalsPublickey(response.addresses[0].publicKey);
          appContext?.updatePaymentAddress(response.addresses[1].address);
          appContext?.updatePaymentPublickey(response.addresses[1].publicKey);
          appContext?.updateWalletType(WalletType.Xverse);
          appContext?.updateUserWallet("1");
          handleOpen();
          toast.success("Connecting successfully");
        },
        onCancel: () => {
          alert("Request canceled");
          toast.warn("Connecting canceled");
        },
        onError: (err: string) => {
          toast.warn(err);
        },
      };
      await getAddress(getAddressOptions).catch((err) => {
        toast.error(err.message);
      });
    } catch (error) {
      toast.warn("Please insatll the Xverse wallet!!");
    }
  };

  const connectUnisatWallet = async (e: any) => {
    e.preventDefault()
    try {
      const accounts = await (window as any).unisat.requestAccounts();
      // TODO: getPubkey
      const pubkey = await (window as any).unisat.getPublicKey();

      appContext?.updatePaymentAddress(accounts[0]);
      appContext?.updateOrdinalsAddress(accounts[0]);
      appContext?.updatePaymentPublickey(pubkey);
      appContext?.updateOrdinalsPublickey(pubkey);
      appContext?.updateWalletType(WalletType.Unisat);

      toast.success("Connecting successfully");
    } catch (e) {
      toast.warn("Please install the unisat wallet in your browser");
    }
  };

  const connectLeatherWallet = async (e: any) => {
    e.preventDefault()
    try {
      const userAddresses = await (window as any).btc?.request("getAddresses");

      const usersNativeSegwitAddress = userAddresses.result.addresses.find(
        (address: { type: string }) => address.type === "p2wpkh"
      );


      appContext?.updateOrdinalsAddress(usersNativeSegwitAddress.address);
      appContext?.updateOrdinalsPublickey(usersNativeSegwitAddress.publicKey);
      appContext?.updatePaymentPublickey(usersNativeSegwitAddress.address);
      appContext?.updatePaymentPublickey(usersNativeSegwitAddress.publicKey);
      appContext?.updateWalletType(WalletType.Leather);

      toast.success("Connecting successfully");
    } catch (error) {
      toast.warn("Please install the leather wallet in your browser");
    }
  };

  const connectPhantomWallet = async (e: any) => {
    e.preventDefault()
    try {
      const phantomProvider = getProvider();
      const accounts = await phantomProvider.requestAccounts();

      appContext?.updatePaymentAddress(accounts[0].address);
      appContext?.updatePaymentPublickey(accounts[0].publicKey);
      appContext?.updateOrdinalsAddress(accounts[1].address);
      appContext?.updateOrdinalsPublickey(accounts[1].publicKey);
      // TODO: phantom pubkey
      appContext?.updateWalletType(WalletType.Phatom);

    } catch (error) {
      toast.warn("Please insatll the Xverse wallet!!");
    }
  };

  const connectOkmWallet = async (e: any) => {
    e.preventDefault()
    try {
      const response = await (window as any).okxwallet.bitcoin.connect();
      const accounts = await (window as any).okxwallet.bitcoin.requestAccounts();
      const pubkey = await (window as any).okxwallet.bitcoin.getPublicKey();

      appContext?.updatePaymentAddress(accounts[0]);
      appContext?.updateOrdinalsAddress(accounts[0]);
      appContext?.updatePaymentPublickey(pubkey);
      appContext?.updateOrdinalsPublickey(pubkey);
      appContext?.updateWalletType(WalletType.OKX);

    } catch (err) {
      toast.error("You need check if the wallet is correctly installed")
    }
  };

  const connectMEWallet = async (e: any) => {
    e.preventDefault()
    for (const wallet of wallets.filter(isSatsConnectCompatibleWallet)) {
      setWallet(wallet)
    }

    try {
      await getAddress({
        getProvider: async () =>
          (wallet as unknown as WalletWithFeatures<any>).features[SatsConnectNamespace]?.provider,
        payload: {
          purposes: [AddressPurpose.Ordinals, AddressPurpose.Payment],
          message: 'Address for receiving Ordinals and payments',
          network: {
            type: BitcoinNetworkType.Mainnet,
          },
        },
        onFinish: (response) => {
          connectionStatus?.setAccounts(response.addresses as unknown as Account[]);
          appContext?.updateOrdinalsAddress(response.addresses[0].address);
          appContext?.updateOrdinalsPublickey(response.addresses[0].publicKey);
          appContext?.updatePaymentAddress(response.addresses[1].address);
          appContext?.updatePaymentPublickey(response.addresses[1].publicKey);

          handleOpen();
          appContext?.updateWalletType(WalletType.MagicEden);
        },
        onCancel: () => {
          toast.error("You need check if the wallet is correctly installed")
        },

      }
      );

    } catch (err) {
      toast.error("You need check if the wallet is correctly installed")
    }
  }

  const handleChange = (e: any) => {
    setWalletName(e.target.value)

  }

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(`${appContext?.paymentAddress}`);
      toast.success("address was copied!");
    } catch (err) {

      alert("Copy to clipboard failed.");
    }
  };

  const walletSubmit = async () => {

    if (!profile) {
      toast.warn("You must add profile!")
    } else if (!walletName) {
      toast.warn("Wallet Name is required!")
    }

    const requestBody = {
      username: profile?.username,
      walletName: walletName,
      paymentAddress: appContext?.paymentAddress,
      paymentPubkey: appContext?.paymentPublickey,
      ordinalsAddress: appContext?.ordinalsAddress,
      ordinalsPubkey: appContext?.ordinalsPublickey,
      walletType: appContext?.walletType
    };
    try {
      const response = await axios.post(`${BACKEND_URL}/profile/addWallet`, requestBody, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const payload = response.data.payload;
      const newProfile = payload.filter((value: IProfile) => value._id === profile?._id);
      setProfile(newProfile[0])
      setIsLoading(false)

    } catch (error) {
      // Handle any errors that occurred during the form submission
      toast.warn("Your wallet address is duplicated!")
    }
    setShowModal(false)
  }


  const removeWallet = async () => {
    console.log("address ==>", selWallet);
    if (!selWallet) return
    try {
      const response = await axios.post(`${BACKEND_URL}/profile/removeWallet`, {
        username: profile?.username,
        paymentAddress: selWallet?.paymentAddress,
      })

      console.log("profile id ==> ", profile?._id)
      console.log('removeWallet response ==> ', response.data);

      if (response.data.success) {
        await fetchProfileByProfileId()
      }
      setIsLoading(false);
      setOpen(false);
      toast.success("deleted")
    } catch (error) {
      alert("remove error");
    }
  }


  const handleSave = async (index: number) => {
    try {
      const response = await axios.post(`${BACKEND_URL}/profile/renameWallet`, {
        id: profile?._id,
        walletName: newWalletName,
        paymentAddress: profile?.address[index].paymentAddress,
      });
      if (response.data.success) {
        // Update the profile with the new wallet name
        const updatedProfile: IProfile = response.data.payload;
        // ...
        setProfile(updatedProfile)
        setEditingIndex(null);
        setIsLoading(false);
      } else {
        // Handle error
      }
    } catch (error) {
    }
  };

  const handleEdit = (index: number) => {
    setEditingIndex(index);
    if (profile)
      setNewWalletName(profile.address[index].walletName);
  };

  const changeWalletName = (e: any) => {
    setNewWalletName(e.target.value)
  }

  if (isLoading) {
    return (
      <Loading />
    )
  }



  return (
    <div className="flex w-full min-h-screen mx-auto ">
      {/* Main content */}
      <div className="flex flex-col w-full">
        {/* header */}
        <div className="flex flex-row justify-between px-4 py-8 ">
          <div className="flex flex-row items-center min-[566px]:hidden">
            <Mydrawer />
            <img src="../assets/Frame.png" alt="Fram"/>
          </div>
          <div className="max-[563px]:hidden"></div>
          <Header />
        </div>
        {/* profile */}
        <div className="flex flex-col px-4">
          {/* Profile Header */}
          <div className="flex flex-row justify-between w-full">
            <p className="text-2xl text-white bold">Profile</p>
            <button className="bg-[#191D24] bold items-center text-white flex flex-row px-4 py-2 rounded-full border border-white-400 hover:bg-blue-700"
              onClick={() => setShowModal(true)}>
              <p className="pr-2 "><BiPlus /></p>
              <label>Add Wallet</label>
            </button>
          </div>
          {/*  */}
          {showModal ? (
            <>
              <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none ">
                <div className="w-auto max-w-3xl mx-auto my-6">
                  <div className="relative flex flex-col w-full border-2 border-gray-700 rounded-lg shadow-lg outline-none ">
                    <div className=" bg-[#252B35] flex flex-row  justify-between p-5 border-b-2 border-solid border-gray-700 ">
                      <div></div>
                      <h3 className="flex text-[20px] font-manrope text-white">Add Wallet</h3>
                      <button
                        className="float-right text-black bg-transparent border-0"
                        onClick={() => setShowModal(false)}
                      >
                        <span className="flex items-center w-6 h-6 py-0 text-xl text-white opacity-7">
                          <IoCloseSharp style={{ color: "white", fontSize: "34px" }} />
                        </span>
                      </button>
                    </div>
                    <div className="flex bg-[#252B35] justify-center p-6 ">
                      <form className="flex flex-col justify-center bg-[#252B35]  shadow-md rounded pb-4 ">
                        <div className="flex flex-col gap-2 ">
                          <label className="text-white text-[14px] font-manrope mb-1">
                            Wallet Name
                          </label>
                          <input className="bg-[#16171B] shadow rounded py-2 focus:outline-none px-2 text-white placeholder:text-gray-600 border-black
                          focus-visible:border"
                            onChange={handleChange} placeholder="Wallet name" />
                        </div>
                        <div className="flex w-full p-2 text-white">
                          <div className="flex grid-cols-3 grid-rows-2 gap-4 pt-6 rounded shadow" >
                            <button
                              className="flex flex-col items-center w-full px-4 py-2 mb-2 font-semibold transition-all rounded gap-x-4"
                              onClick={(e) => connectXverseWallet(e)}
                            >
                              <img src="/assets/xverse.jpg" className="h-8" alt="xverse"/> Xverse
                            </button>
                            <button
                              className="flex flex-col items-center w-full px-4 py-2 mb-2 font-semibold transition-all rounded gap-x-4"
                              onClick={(e) => connectUnisatWallet(e)}
                            >
                              <img src="../assets/unisat.jpg" className="h-8" alt="unisat"/> Unisat
                            </button>
                            <button
                              className="flex flex-col items-center w-full px-4 py-2 mb-2 font-semibold transition-all rounded gap-x-4"
                              onClick={(e) => connectLeatherWallet(e)}
                            >
                              <img src="../assets/leather.png" className="h-8" alt="leather"/> Leather
                            </button>
                            <button
                              className="flex flex-col items-center w-full px-4 py-2 mb-2 font-semibold transition-all rounded gap-x-4"
                              onClick={(e) => connectMEWallet(e)}
                            >
                              <img src="../assets/magic.jpg" className="h-8" alt="magi"/> Magic
                            </button>
                            <button
                              className="flex flex-col items-center w-full px-4 py-2 mb-2 font-semibold transition-all rounded gap-x-4"
                              onClick={(e) => connectPhantomWallet(e)}
                            >
                              <img src="../assets/phantom.png" className="h-8" alt="pahntom"/> Phantom
                            </button>
                            <button
                              className="flex flex-col items-center w-full px-4 py-2 mb-2 font-semibold transition-all rounded gap-x-4"
                              onClick={(e) => connectOkmWallet(e)}
                            >
                              <img src="../assets/okx.png" className="h-8" alt="oxk" /> Okx
                            </button>
                          </div>
                        </div>
                        <div className="flex bg-[#252B35] justify-center pb-3 px-3.5">
                          <button
                            className="bg-[#1665FF] rounded-xl px-6 py-3 w-full"
                            type="button"
                            onClick={walletSubmit}
                          ><p className="text-[14px] text-white font-semibold leading-6 focus:bg-blue-950">Add Wallet</p></button>
                        </div>

                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {/* Profile Avatar */}
          <div className="flex justify-center mb-10">
            {profile ? (<img src={`${BACKEND_URL_PURE}/${profile?.avatar}`} className="h-[231px] w-[231px] rounded-[14px]" alt="ava"/>) :
              (<img src="https://i.pinimg.com/564x/9a/8b/cf/9a8bcfaba81783eff9241538b00343b1.jpg" className="h-40 w-40 rounded-[14px]" alt="alter"/>)}
          </div>
          {/* Wallet list */}
          <div className="flex flex-col gap-2">
            {profile && profile.address ? (profile.address.map((address: IAddress, index: number) =>
              <div key={index} className="flex flex-row  bg-[#252B35] border-gray-700 border-2 rounded-lg p-2 w-full ">
                <div className="flex flex-row justify-between w-full gap-2 ">
                  <div className="flex flex-row items-center gap-2 pl-1 ">
                    <div className="bg-[#637592] bg-opacity-[8%] rounded-lg">
                      <img src="../assets/wallet.png" className="flex w-10 h-10 p-2" alt="wallet"/>
                    </div>
                    <div className="flex flex-col pl-1">
                      {editingIndex === index ? (
                        <input
                          type="text"
                          value={newWalletName}
                          onChange={changeWalletName}
                          className="bg-[#16171B] text-white pl-1 focus:outlone-none"
                        />
                      ) : (
                        <p className="text-white pl-1 text-[16px] font-semibold">{address.walletName}</p>
                      )}
                      <div className="flex flex-row items-center">
                        <p className="text-[#637592] font-manrope pl-1 text-[14px]">{address.walletType} &middot;</p>
                        <div className="flex flex-row items-center duration-300 cursor-pointer hover:brightness-200" onClick={handleCopyClick}>
                          <p className="text-[#637592] font-manrope pl-1 truncate max-w-[100px] text-[14px] ">{address.paymentAddress}</p>
                          <IoCopyOutline style={{ color: "#637592" }} className="duration-300 cursor-pointer hover:brightness-200" />
                        </div>
                      </div>
                    </div>
                    {address.paymentAddress === appContext?.paymentAddress ?
                      <p className="text-white rounded-lg px-3 py-1 bg-gray-900 ml-4 text-[12px] font-manrope font-semibold">
                        &middot; Connected
                      </p> : <></>}
                  </div>
                  <div className="flex flex-row gap-2 p-1">
                    {editingIndex === index ? <div className="bg-[#191D24] bold text-white items-center flex flex-row p-1 rounded-lg border border-white-400 cursor-pointer hover:brightness-200 duration-300 gap-2 px-2"
                      onClick={() => handleSave(index)}
                    >
                      <IoIosSave />
                      <p>Save</p>
                    </div> : <div className="flex flex-row bg-[#191D24] bold text-white items-center px-2 py-1  rounded-lg border border-white-400 gap-2 hover:brightness-200 duration-300 cursor-pointer"
                      onClick={() => handleEdit(index)}
                    >
                      <img src="../assets/edit.png" alt="edit"/>
                      <p className="max-sm:hidden">Edit</p>
                    </div>}
                    {editingIndex === index ? <div className="bg-[#191D24] bold text-white items-center flex flex-row p-1 rounded-lg border border-white-400 cursor-pointer hover:brightness-200 duration-300 gap-2 px-6"
                      onClick={() => setEditingIndex(-1)}
                    >
                      <TiCancel />
                      <p>Cancel</p>
                    </div> : address.paymentAddress != appContext?.paymentAddress ? <div key={index} className="flex flex-row bg-[#191D24] bold text-white justify-between items-center px-2 py-1 rounded-lg border border-red-400 gap-2 hover:bg-red-500 hover:text-white duration-300 cursor-pointer" onClick={() => handleClickOpen(address)}>
                      <img src="../assets/delete.png " alt="dele"/>
                      <p className="text-red max-sm:hidden" >Disconnect</p>
                    </div> : <div key={index} className="flex flex-row bg-[#191D24] bold text-white justify-between items-center px-2 py-1 rounded-lg border border-gray-600 gap-2  duration-300 cursor-not-allowed">
                      <img src="../assets/delete.png " alt="delte"/>
                      <p className="text-gray-700 text-red max-sm:hidden" >Disconnect</p>
                    </div>}
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      style={{ borderColor: "black", borderStyle: "solid" }}
                    >
                      <DialogTitle id="alert-dialog-title">
                        <div className="flex flex-row items-center gap-4">
                          <IoMdWarning size={30} color="red" />{"Delete Confirmation?"}
                        </div>

                      </DialogTitle>
                      <DialogContent style={{ color: "#16171B" }}>
                        <DialogContentText id="alert-dialog-description" >
                          Are you sure to delete this wallet?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => removeWallet()}>
                          <div className="flex flex-row items-center gap-2 p-2 pr-3 duration-300 bg-red-600 rounded-xl hover:brightness-200">
                            <MdDeleteForever size={20} color="white" />
                            <p className="text-white">Yes</p>
                          </div>
                        </Button>
                        <Button onClick={handleClose}>No</Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </div>
              </div>)) : (<p className="flex items-center justify-center text-red-500 align-center">No Data</p>)}
          </div>

        </div>
      </div>
      <ToastContainer />
    </div>

  );
}

