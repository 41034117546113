import { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { validate, Network } from "bitcoin-address-validation";

import { IoMdWarning } from "react-icons/io";
import { MdDeleteForever } from "react-icons/md";

import Header from "../component/Header";
import { IProfile, ITx } from "../type";
import Mydrawer from "../component/collapse";
import { AppContext } from "../context/UserContext";
import {
  BACKEND_URL,
  NEXT_PUBLIC_ORDINAL_URL,
  TEST_MODE,
  WalletType,
} from "../configs/config";
import { Loading } from "../component/loading";
import {
  BitcoinNetworkType,
  SignTransactionOptions,
  signTransaction,
} from "sats-connect";
interface IErr {
  destination: string;
  type: string;
  sendInscribe: string;
  amountToTransfer: string;
  feeRate: string;
}


export const Send = () => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const [showDraft, setShowDraft] = useState(false);
  const [showOrdinals, setShowOrdinals] = useState(false);
  const [showBtc, setShowBtc] = useState(true);
  const [profile, setProfile] = useState<IProfile>();
  const [recommendFeeRate, setRecommendFeeRate] = useState(0);
  const [minFeeRate, setMinFeeRate] = useState(0);
  const [inscribe, setInscribe] = useState([]);
  const [sendInscribe, setsendInscribe] = useState("");
  const [ordinalIndex, setOrdinalIndex] = useState(-1);

  const [repeatModal, setRepeatModal] = useState(false);
  const [repeatData, setRepeatData] = useState<ITx>();
  const [isLoading, setIsLoading] = useState(true);
  const [txId, setTxId] = useState("");

  const destinationRef = useRef(null);
  const amountToTransferRef = useRef(null);
  const feeRateRef = useRef(null);

  const network = TEST_MODE ? Network.testnet : Network.mainnet;

  const [err, setErr] = useState<IErr>();

  const ordinalsCardHandler = (inscriptionId: string, index: number) => {
    setsendInscribe(inscriptionId);
    if (ordinalIndex === index) {
      setOrdinalIndex(-1);
    } else {
      setOrdinalIndex(index);
    }
  };

  const getProfileByPaymentAddress = async () => {
    try {
      const response = await axios.get(
        `${BACKEND_URL}/profile/getByPaymentAddress?paymentAddress=${appContext?.paymentAddress}`
      );
      setProfile(response.data.payload);
      setIsLoading(false);
    } catch (error) {
      throw error;
    }
  };

  const fetchInscriptions = async () => {
    console.log("fetching inscriptions for slug", appContext?.ordinalsAddress);
    const response = await axios.get(
      `${BACKEND_URL}/utils/getInscriptions?address=${appContext?.ordinalsAddress}`
    );
    if (response.status === 200) {
      const data = await response.data.payload;
      return data;
    } else {
      return undefined;
    }
  };

  const getInscription = async () => {
    try {
      const inscriptions = await fetchInscriptions();
      console.log("inscriptions ==> ", inscriptions);
      // const allInscriptions = inscriptions?.data?.inscription;
      if (inscriptions) {
        const filteredInscription = inscriptions.filter(
          (item: any) => item.brc20 === null
        );
        setInscribe(filteredInscription);
      }
    } catch (error) {
      console.log("error ==> ", error);
    }
  };

  const handleClose = () => {
    setRepeatModal(false);
  };

  useEffect(() => {
    getProfileByPaymentAddress();
  }, [profile]);

  useEffect(() => {
    appContext?.ordinalsAddress && getInscription();
  }, [appContext?.ordinalsAddress]);

  const checkOrdinal = () => {
    setShowOrdinals(!showOrdinals);
    setShowBtc(false);
  };

  const checkBtc = () => {
    setShowBtc(!showBtc);
    if (showOrdinals) {
      setShowOrdinals(false);
    }
  };

  const checkDraft = () => {
    setShowDraft(!showDraft);
  };

  const getFeeRate = async () => {
    try {
      const payload = await axios.get(`${BACKEND_URL}/utils/getFeeRate`);
      if (payload.data.success) {
        const temp = payload.data.payload.fastestFee;
        const tempMinValue = payload.data.payload.minimumFee;
        setRecommendFeeRate(temp);
        setMinFeeRate(tempMinValue);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkRepeatance = async () => {
    try {
      if (showOrdinals) {
        const url = `${BACKEND_URL}/tx/checkInscRepeat?profileId=${profile?._id}&paymentAddress=${appContext?.paymentAddress}&inscriptionId=${sendInscribe}`;
        console.log("url ==> ", url);

        const temp = await axios.get(url);
        console.log("checkRepeatance ==> ", temp.data.success);
        console.log("payload ==> ", temp.data);

        setRepeatData(temp.data.payload);
        return temp.data.success;
      } else {
        return false;
      }
    } catch (error) {
      console.log("checkRepeatance ==> ", error);
    }
  };

  function hexToBase64(hexStr: string): string {
    // Convert hex string to byte array
    const bytes = new Uint8Array(
        hexStr.match(/.{1,2}/g)!.map(byte => parseInt(byte, 16))
    );

    // Convert byte array to base64 string
    const base64String = btoa(String.fromCharCode.apply(null, bytes as any));

    return base64String;
}
  const onSubmit = async () => {
    try {
      setIsLoading(true);
      setTxId("");
      // TODO validation
      let errFlag = false;
      let tempError: IErr = {
        destination: "",
        type: "",
        sendInscribe: "",
        amountToTransfer: "",
        feeRate: "",
      };

      let destinationAddress = "";
      let type = "";
      let amountToTransfer = "";

      if (destinationRef.current) {
        destinationAddress = destinationRef.current["value"];
      }

      if (showOrdinals || showBtc) {
        type = showOrdinals ? "Ordinals" : "BTC";
      }

      if (amountToTransferRef.current) {
        amountToTransfer = amountToTransferRef.current["value"];
        console.log("amountToTransfer ==>", amountToTransfer);
      }

      const isDraft = showDraft ? true : false;

      let feeRate = "";

      if (feeRateRef.current) {
        feeRate = feeRateRef.current["value"];
      }

      if (parseInt(feeRate) < minFeeRate) {
        tempError.feeRate = `You should set Fee above minFeeRate ${minFeeRate}`;
        setIsLoading(false);
        return;
        // throw `You should set Fee above minFeeRate ${minFeeRate}$`
      }

      // Verify Step

      if (!destinationAddress) {
        tempError.destination = "Destination address is required";
        errFlag = true;
      } else {
        if (!validate(destinationAddress)) {
          tempError.destination = "Destination address is invalid";
          errFlag = true;
        } else {
          if (!validate(destinationAddress, network)) {
            tempError.destination = `Destination address is ${
              TEST_MODE ? "Mainnet address" : "Testnet address"
            }`;
            errFlag = true;
          }
        }
      }

      if (!type) {
        tempError.type = "Type is required";
        errFlag = true;
      }

      if (type === "BTC") {
        if (!amountToTransfer) {
          tempError.amountToTransfer = "AmountToTransfer is required";
          errFlag = true;
        }
      } else if (type === "Ordinals") {
        if (!sendInscribe) {
          tempError.sendInscribe = "Inscribe to be sent is required";
          errFlag = true;
        }
      }

      if (!isDraft) {
        if (!feeRate) {
          tempError.feeRate = "feeRate is required";
          errFlag = true;
        }
      }

      console.log("tempError ==>", tempError);
      if (errFlag) {
        setErr(tempError);
        setIsLoading(false);
        return;
      }
      const isRepeated = await checkRepeatance();
      if (!isRepeated) {
        console.log("new transaction ==> ");
        const sendingPayload = {
          profileId: profile?._id,
          paymentAddress: appContext?.paymentAddress,
          paymentPubkey: appContext?.paymentPublickey,
          ordinalsAddress: appContext?.ordinalsAddress,
          ordinalsPubkey: appContext?.ordinalsPublickey,
          walletType: appContext?.walletType,
          destinationAddress,
          type,
          amountToTransfer: amountToTransfer ? parseFloat(amountToTransfer) : 0,
          inscriptionId: showOrdinals ? sendInscribe : "",
          isDraft,
          feeRate: feeRate ? parseInt(feeRate) : 0,
        };

        console.log("payload ==> ", sendingPayload);

        const receivePayload = await axios.post(
          `${BACKEND_URL}/tx/saveTx`,
          sendingPayload
        );
        console.log("receivePayload ==> ", receivePayload.data);

        // TODO receivePayload true or not

        if (isDraft === true) {
          toast.success("Transaction is saved successfully!");
          setIsLoading(false);
          return;
        }
        const psbt = receivePayload.data.payload;
        console.log(psbt);
        
        const base64Str = hexToBase64(psbt);
        console.log(base64Str);
        
        let signedPSBT;
        // TODE
        if (appContext?.walletType === WalletType.Unisat) {
          const signedPSBT = await (window as any).unisat.signPsbt(psbt);
          console.log("signedPSBT ==> ", signedPSBT);
          const txIdPayload = await axios.post(`${BACKEND_URL}/tx/exec`, {
            psbt,
            signedPSBT,
            walletType: appContext?.walletType,
            profileId: profile?._id,
            paymentAddress: appContext?.paymentAddress,
            paymentPubkey: appContext?.paymentPublickey,
            ordinalsAddress: appContext?.ordinalsAddress,
            ordinalsPubkey: appContext?.ordinalsPublickey,
            destinationAddress,
            type,
            amountToTransfer: amountToTransfer
              ? parseFloat(amountToTransfer)
              : 0,
            inscriptionId: showOrdinals ? sendInscribe : "",
            isDraft,
            feeRate: feeRate ? parseInt(feeRate) : 0,
          });
          const txId = txIdPayload.data.msg;
          setInscribe([]);
          await getInscription();

          console.log("txID ==> ", txId);

          toast.success("Transaction is broadcasting successfully...");
          // toast.success("txId is ", txId);
          setIsLoading(false);
          setTxId(txId);
        } else if (appContext?.walletType == WalletType.Xverse) {
          console.log("Xverse wallet");
          const signPsbtOptions: SignTransactionOptions = {
            payload: {
              network: {
                type: TEST_MODE
                  ? BitcoinNetworkType.Testnet
                  : BitcoinNetworkType.Mainnet,
              },
              message: "Sign Transaction",
              psbtBase64: base64Str,
              broadcast: false,
              inputsToSign: [
                { address: appContext?.paymentAddress, signingIndexes: [0] },
              ],
            },
            onFinish: (response: any) => {
              console.log("response==========>", response);
              signedPSBT = response.psbtBase64;
            },
            onCancel: () => alert("Canceled"),
          };
          await signTransaction(signPsbtOptions);
          console.log("signedpSBT=====>", signedPSBT);
          const txIdPayload = await axios.post(`${BACKEND_URL}/tx/exec`, {
            psbt,
            signedPSBT,
            walletType: appContext?.walletType,
            profileId: profile?._id,
            paymentAddress: appContext?.paymentAddress,
            paymentPubkey: appContext?.paymentPublickey,
            ordinalsAddress: appContext?.ordinalsAddress,
            ordinalsPubkey: appContext?.ordinalsPublickey,
            destinationAddress,
            type,
            amountToTransfer: amountToTransfer
              ? parseFloat(amountToTransfer)
              : 0,
            inscriptionId: showOrdinals ? sendInscribe : "",
            isDraft,
            feeRate: feeRate ? parseInt(feeRate) : 0,
          });
          const txId = txIdPayload.data.msg;
          setInscribe([]);
          await getInscription();

          console.log("txID ==> ", txId);

          toast.success("Transaction is broadcasting successfully...");
          // toast.success("txId is ", txId);
          setIsLoading(false);
          setTxId(txId);
        } else if(appContext?.walletType===WalletType.Leather){
            const signedPSBT = await (window as any).btc?.signPsbt(psbt)
            console.log("signedPSBT ==> ", signedPSBT);
            const txIdPayload = await axios.post(`${BACKEND_URL}/tx/exec`, {
              psbt,
              signedPSBT,
              walletType: appContext?.walletType,
              profileId: profile?._id,
              paymentAddress: appContext?.paymentAddress,
              paymentPubkey: appContext?.paymentPublickey,
              ordinalsAddress: appContext?.ordinalsAddress,
              ordinalsPubkey: appContext?.ordinalsPublickey,
              destinationAddress,
              type,
              amountToTransfer: amountToTransfer
                ? parseFloat(amountToTransfer)
                : 0,
              inscriptionId: showOrdinals ? sendInscribe : "",
              isDraft,
              feeRate: feeRate ? parseInt(feeRate) : 0,
            });
            const txId = txIdPayload.data.msg;
            setInscribe([]);
            await getInscription();
  
            console.log("txID ===> ", txId);
  
            toast.success("Transaction is broadcasting successfully...");
            // toast.success("txId is ", txId);
            setIsLoading(false);
            setTxId(txId);
        } else if(appContext?.walletType===WalletType.OKX){
            const signedPSBT = await (window as any).okxwallet.bitcoin.signPsbt(psbt);
            console.log("signedPSBT ==> ", signedPSBT);
            const txIdPayload = await axios.post(`${BACKEND_URL}/tx/exec`, {
              psbt,
              signedPSBT,
              walletType: appContext?.walletType,
              profileId: profile?._id,
              paymentAddress: appContext?.paymentAddress,
              paymentPubkey: appContext?.paymentPublickey,
              ordinalsAddress: appContext?.ordinalsAddress,
              ordinalsPubkey: appContext?.ordinalsPublickey,
              destinationAddress,
              type,
              amountToTransfer: amountToTransfer
                ? parseFloat(amountToTransfer)
                : 0,
              inscriptionId: showOrdinals ? sendInscribe : "",
              isDraft,
              feeRate: feeRate ? parseInt(feeRate) : 0,
            });
            const txId = txIdPayload.data.msg;
            setInscribe([]);
            await getInscription();
  
            console.log("txID ==> ", txId);
  
            toast.success("Transaction is broadcasting successfully...");
            // toast.success("txId is ", txId);
            setIsLoading(false);
            setTxId(txId);
        }
      } else {
        console.log("Repeated inscription");
        setRepeatModal(true);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Transaction get failed...");
      console.log("submit ==> ", error);
    }
  };

  const overWrite = async () => {
    try {
      let destinationAddress = "";
      let type = "";
      let amountToTransfer = "";

      if (destinationRef.current) {
        destinationAddress = destinationRef.current["value"];
      }

      if (showOrdinals || showBtc) {
        type = showOrdinals ? "Ordinals" : "BTC";
      }

      if (amountToTransferRef.current) {
        amountToTransfer = amountToTransferRef.current["value"];
      }

      const isDraft = showDraft ? true : false;

      let feeRate = "";

      if (feeRateRef.current) {
        feeRate = feeRateRef.current["value"];
      }

      const payload = {
        previousId: repeatData?._id,
        destinationAddress,
        isdraft: showDraft,
        profileId: profile?._id,
        paymentAddress: appContext?.paymentAddress,
        paymentPubkey: appContext?.paymentPublickey,
        ordinalsAddress: appContext?.ordinalsAddress,
        ordinalsPubkey: appContext?.ordinalsPublickey,
        walletType: appContext?.walletType,
        type,
        amountToTransfer: amountToTransfer ? parseFloat(amountToTransfer) : 0,
        inscriptionId: showOrdinals ? sendInscribe : "",
        isDraft,
        feeRate: feeRate ? parseInt(feeRate) : 0,
      };

      const repeatPayload = await axios.post(
        `${BACKEND_URL}/tx/overwriteTx`,
        payload
      );
      console.log("repeatePayload ==> ", repeatPayload.data);

      setRepeatModal(false);

      if (showDraft) {
        setIsLoading(false);
        return;
      }

      const psbt = repeatPayload.data.payload;
      const signedPSBT = await (window as any).unisat.signPsbt(psbt);

      const txIdPayload = await axios.post(`${BACKEND_URL}/tx/exec`, {
        psbt,
        signedPSBT,
        walletType: appContext?.walletType,
        profileId: profile?._id,
        paymentAddress: appContext?.paymentAddress,
        paymentPubkey: appContext?.paymentPublickey,
        ordinalsAddress: appContext?.ordinalsAddress,
        ordinalsPubkey: appContext?.ordinalsPublickey,
        destinationAddress,
        type,
        amountToTransfer: amountToTransfer ? parseFloat(amountToTransfer) : 0,
        inscriptionId: showOrdinals ? sendInscribe : "",
        isDraft,
        feeRate: feeRate ? parseInt(feeRate) : 0,
      });
      const txId = txIdPayload.data.msg;
      setInscribe([]);
      await getInscription();
      setIsLoading(false);
      toast.success("transactino broadcasting successfully...");
      // toast.success(txId);
      setTxId(txId);
    } catch (error) {
      console.log("error in overWrite ==> ", error);
      toast.error("Transaction get failed...");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFeeRate();
  }, [recommendFeeRate,minFeeRate]);

  return (
    <div className="flex w-full h-screen overflow-auto">
      <div className="flex flex-col w-full">
        {isLoading ? <Loading /> : <></>}

        {/* header */}
        <div className="flex flex-row justify-between px-4 py-8 ">
          <div className="flex flex-row items-center min-[566px]:hidden">
            <Mydrawer />
            <img src="../assets/Frame.png" alt="afe" />
          </div>sdf
          <div className="max-[563px]:hidden"></div>
          <Header />
        </div>

        {/* RBF */}
        <div className="flex items-center justify-between pt-4">
          <div className="flex flex-col gap-2 bg-[#191D24] mx-auto border-2 min-[640px]:w-[500px] max-[640px]:w-[450px] border-solid border-[#252B35] rounded-xl p-6 ">
            <div className="flex flex-row justify-center px-4 py-5">
              <h3 className="text-[24px]  font-bold font-manrope text-white leading-8">
                Send
              </h3>
            </div>
            <div className="flex flex-col">
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-1">
                  <label className="font-manrope text-[14px] font-normal leading-6 text-[#637592]">
                    Destination Address
                  </label>
                  <input
                    name="destinationAddress"
                    className="bg-[#16171B] rounded-xl px-4 py-3 gap-2 placeholder:text-gray-600 text-white focus:outline-none "
                    placeholder="ex. 3Eb9zqd..."
                    ref={destinationRef}
                  ></input>
                  {err ? (
                    <p className="text-red-600">{err.destination}</p>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="flex flex-col gap-1">
                  <label className="font-manrope text-[14px] font-normal leading-6 text-[#637592]">
                    Type
                  </label>
                  <div className="flex flex-row justify-start w-full">
                    <div className="flex items-center font-manrope text-[14px] font-normal leading-6 text-white gap-1 w-1/2">
                      <input
                        type="checkbox"
                        name="Ordinals"
                        className="accent-[#1665FF] bg-[#637592] focus:outline-none rounded-md w-[24px] h-[24px]"
                        checked={showOrdinals}
                        onChange={checkOrdinal}
                      />
                      <p>Ordinal</p>
                    </div>

                    <div className="flex items-center gap-1 font-manrope text-[14px] font-normal leading-6 text-white w-1/2">
                      <input
                        type="checkbox"
                        name="BTC"
                        className="accent-[#1665FF] bg-[#637592] focus:outline-none w-[24px] h-[24px]"
                        checked={showBtc}
                        onChange={checkBtc}
                      />
                      <p>BTC</p>
                    </div>
                    {err ? <p className="text-red-600">{err.type}</p> : <></>}
                  </div>
                </div>

                {showOrdinals && (
                  <div className="flex flex-col gap-1">
                    <label className="font-manrope text-[14px] leading-6 font-normal text-[#637592]">
                      Select Ordinals
                    </label>
                    {/* <input
                                            className="bg-[#16171B] rounded-xl px-4 py-3 gap-2 text-[#637592] focus:outline-none" name="ordinals"
                                        /> */}

                    <div className="flex flex-wrap justify-between gap-2 max-h-[200px] overflow-auto">
                      {/* Ordinals Card */}
                      {inscribe ? (
                        inscribe.map((ordinal: any, index: number) => (
                          <div className="relative">
                            {index === ordinalIndex ? (
                              <div className="absolute bottom-0 left-0 right-0 z-10 bg-white bg-opacity-80 animate-pulse top-4"></div>
                            ) : (
                              <></>
                            )}
                            <div
                              className="relative flex flex-col w-20 h-[104px] mt-4 border border-gray-400 cursor-pointer"
                              onClick={() =>
                                ordinalsCardHandler(
                                  ordinal.inscriptionId,
                                  index
                                )
                              }
                            >
                              <img
                                alt="Inscription Image"
                                className="w-20 h-20"
                                src={`${NEXT_PUBLIC_ORDINAL_URL}/${ordinal.inscriptionId}`}
                              />
                              <div className="text-black text-center bg-white text-[16[px]]">
                                {ordinal.inscriptionNumber}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <></>
                      )}
                    </div>
                    {err ? (
                      <p className="text-red-600">{err.sendInscribe}</p>
                    ) : (
                      <></>
                    )}
                  </div>
                )}

                {showBtc && (
                  <div className="flex flex-col gap-1">
                    <label className="font-manrope text-[14px] leading-6 font-normal text-[#637592]">
                      Amount to transfer
                    </label>
                    <input
                      className="bg-[#16171B] rounded-xl px-4 py-3 gap-2 text-[#637592] focus:outline-none placeholder:text-gray-600"
                      name="amounttoTransfer"
                      placeholder="0.0005 BTC"
                      ref={amountToTransferRef}
                    />
                    {err ? (
                      <p className="text-red-600">{err.amountToTransfer}</p>
                    ) : (
                      <></>
                    )}
                  </div>
                )}

                <div className="flex flex-row justify-start gap-2 pt-2">
                  <input
                    type="checkbox"
                    name="isDraft"
                    className="accent-[#1665FF] bg-[#637592] focus:outline-none w-[24px] h-[24px]"
                    onChange={checkDraft}
                  />
                  <p className="text-white">Draft transaction</p>
                </div>

                {!showDraft && (
                  <div className="flex flex-col gap-1 ">
                    <label className="font-manrope text-[14px] leading-6 font-normal text-[#637592]">
                      Fee Rate
                    </label>
                    <input
                      className="bg-[#16171B] rounded-xl px-4 py-3 gap-2 text-[#637592] focus:outline-none placeholder:text-gray-600"
                      name="feeRate"
                      placeholder="Input fee rate"
                      ref={feeRateRef}
                    ></input>
                    {err ? (
                      <p className="text-red-600">{err.feeRate}</p>
                    ) : (
                      <></>
                    )}
                    <label className="font-manrope text-[14px] leading-6 font-normal text-[#637592]">
                      *recommended feeRate between {minFeeRate + 5} ~{" "}
                      {recommendFeeRate + 5} sats/vb
                    </label>
                  </div>
                )}
              </div>
              <button
                className="bg-[#21262F] rounded-xl px-6 py-3 w-full hover:bg-[#21263E] mt-4"
                type="submit"
                onClick={() => onSubmit()}
              >
                <p className="text-white font-manrope text-[14px] font-semibold leading-6 ">
                  Submit
                </p>
              </button>

              {txId ? (
                <div className="text-white break-words">
                  txId is
                  <p
                    className="text-blue-800 underline duration-300 cursor-pointer underline-offset-1 hover:brightness-150"
                    onClick={() => navigate(`/detail/${txId}`)}
                  >
                    {txId}
                  </p>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>

        <Dialog
          open={repeatModal}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ borderColor: "black", borderStyle: "solid" }}
        >
          <DialogTitle id="alert-dialog-title">
            <div className="flex flex-row items-center gap-4">
              <IoMdWarning size={30} color="red" />
              {"Repeat Warning"}
            </div>
          </DialogTitle>
          <DialogContent style={{ color: "#16171B" }}>
            <DialogContentText id="alert-dialog-description">
              <p className="mb-10 font-semibold">
                This inscription is already arranged to{" "}
                {repeatData?.destinationAddress}.
              </p>
            </DialogContentText>
            <DialogContentText>Do you want overwite it?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => overWrite()}>
              <div className="flex flex-row items-center gap-2 p-2 pr-3 duration-300 bg-red-600 rounded-xl hover:brightness-200">
                <MdDeleteForever size={20} color="white" />
                <p className="text-white">Yes</p>
              </div>
            </Button>
            <Button onClick={handleClose}>No</Button>
          </DialogActions>
        </Dialog>
      </div>

      <ToastContainer />
    </div>
  );
};
