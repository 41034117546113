import { useContext, useEffect, useState } from 'react'
import Mydrawer from '../component/collapse'
import { AppContext } from '../context/UserContext';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Drafttable from '../component/draft';
import { ITransaction } from '../type';
import { BACKEND_URL } from '../configs/config';
import { NoTransaction } from '../component/notransaction'
import Header from '../component/Header';


export default function DraftTransaction() {
    const appContext = useContext(AppContext)

    const [transactions, setTransactions] = useState<ITransaction[]>([]);

    const getDraftTx = async () => {

        try {
            const response = await axios.get<{ success: boolean; message: string; payload: ITransaction[] }>(`${BACKEND_URL}/tx/getDraftTx`, {
                params: {
                    profileId: appContext?.profileId
                },
            });

            console.log("getDraftTx response ==> ", response.data.payload);

            if (response.data.success) {
                setTransactions(response.data.payload);
            } else {
                console.log(response.data.message);
            }
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getDraftTx();
    }, [transactions]);

    return (
        <div className="flex w-full min-h-screen mx-auto ">
            <div className="flex flex-col w-full min-h-screen">

                {/* user profile */}
                <div className="flex flex-row justify-between px-4 py-8 ">
                    <div className="flex flex-row items-center min-[566px]:hidden">
                        <Mydrawer />
                        <img src="../assets/Frame.png" alt='Frm'/>
                    </div>
                    <div className="max-[563px]:hidden"></div>
                    
                    {/* Header */}
                    <Header />
                </div>

                {/* Title */}
                <div className='flex flex-row justify-between px-8 '>
                    <p className="bold text-[32px] text-white leading-10">Draft Transaction</p>
                    <div></div>
                </div>

                {/* table */}
                <div className="flex flex-col justify-between gap-6 px-8 py-6">
                    {transactions.length ? (<Drafttable />) : (<NoTransaction />)}
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

